module.exports = {
  model: {
    id: '',
    name: '',
    conductor: false,
    identification: '',
    company: '',
  },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercaseFirstLetter: true },
        label: 'Nome completo',
        model: 'name',
        validator: ['required'],
        placeholder: 'Informe o nome completo',
        required: true,
      },
      {
        type: 'custom-checkbox',
        label: 'Condutor',
        model: 'conductor',
        checkboxText: 'Selecione se este usuário for o condutor',
        required: false,
      },
      {
        type: 'input',
        inputType: 'number',
        label: 'Documento de identificação',
        model: 'identification',
        validator: ['required'],
        placeholder: 'Informe um documento de identificação',
        required: true,
      },
      {
        type: 'custom-input',
        options: { uppercaseFirstLetter: true },
        label: 'Empresa',
        model: 'company',
        validator: ['required'],
        placeholder: 'Informe uma empresa',
        required: true,
      },
    ],
  },
  columns: [
    { key: 'name', label: 'Nome', filter: true },
    { key: 'identification', label: 'Identificação', filter: true },
    { key: 'company', label: 'Empresa', filter: true },
  ],
  viewDataTypes: [
    { key: 'name', label: 'Nome' },
    { key: 'conductor', label: 'Condutor' },
    { key: 'identification', label: 'Identificação' },
    { key: 'company', label: 'Empresa' },
  ],
}
