<template>
  <div class="home">
    <custom-header
      type="additional-users"
      title="Usuários Adicionais"
      labelButton="Novo Usuário"
      @create="openModal"
    ></custom-header>
    <custom-table
      :columns="columns"
      :options="true"
      type="additional-users"
      @update="openModal"
      @view="view"
      @delete="this.delete"
      :rows="this.allAdditionalUsers"
    ></custom-table>
    <modal
      v-if="isModalVisible"
      @close="closeModal"
      @submit="save()"
      :schema="schema"
      :model="model"
    ></modal>
    <view-data :viewData="viewData" :viewDataTypes="viewDataTypes"></view-data>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/additional-users/store/service'
import AdditionalUser from '../models/additional-user'

export default {
  name: 'AdditionalUsers',
  data() {
    return {
      columns: AdditionalUser.columns,
      model: AdditionalUser.model,
      viewDataTypes: AdditionalUser.viewDataTypes,
      schema: AdditionalUser.schema,
      viewData: {},
      isModalVisible: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('additionalUsers', ['allAdditionalUsers']),
  },
  methods: {
    ...mapActions('additionalUsers', ['getAllAdditionalUsers']),
    async init() {
      await this.getAllAdditionalUsers()
    },
    openModal(item) {
      if (item) {
        this.model = item
      }

      this.showModal()
    },
    view(item) {
      this.viewData = {
        ...item,
      }

      this.viewData.conductor = this.viewData.conductor ? 'Sim' : 'Não'
      this.$modal.show('view')
    },
    async save() {
      const additionalUser = this.model

      if (
        additionalUser.conductor &&
        !this.$validateCnh(additionalUser.identification)
      ) {
        this.$error(
          'Se o usuário for um condutor, é obrigatório informar a CNH'
        )
        return
      }

      if (
        !this.$validateCnh(additionalUser.identification) &&
        !this.$validateCpf(additionalUser.identification)
      ) {
        this.$error('O documento de identificação não é válido')
        return
      }

      try {
        const save = additionalUser.id
          ? service.updateAdditionalUser(additionalUser)
          : await service.saveAdditionalUser(additionalUser)
        await this.$success('Usuário Adicional')
        location.reload(true)
      } catch (error) {
        this.$error(error)
      }
    },
    async delete({ id }) {
      try {
        await service.deleteAdditionalUser(id)
        await this.$delete('Usuário Adicional')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.model = {}
    },
  },
}
</script>

<style scoped></style>
